<template>
  <div id="ServicesCard" :class="getTileClass">
    <div class="backgroundContainer" :style="{ backgroundImage: 'url(' + serviceData.img + ')' }"></div>
    <div class="unexpandedOverlay" @click="handleOverlayClick" :style="{backgroundImage: `url('${serviceData.background_vector}')`}">
      <div class="textContainer" :style="{paddingBottom: sizing.subtitle}">
<!--          <div class="subtitle" :style="{fontSize: sizing.subtitle}">{{serviceData.subTitle}}</div>-->
          <div class="tileLabel" :style="{fontSize: sizing.tileLabel, lineHeight: sizing.tileLabel}" :class="cardCoverColour">{{serviceData.label}}</div>
      </div>
    </div>
    <div class="expandedOverlay" :style="{width: standardTileWidth * increaseFactor +'px', backgroundImage: 'url(' + serviceData.img + ')'}" >
      <div class="expandedOverlayInner" :style="{paddingTop: sizing.overlayTop}">
        <div class="expandedTitle" :style="{fontSize: sizing.expandedTitle}">{{serviceData.label}}</div>
        <div class="btnRow my-3">
          <div class="moreButton"
               @click="goToContent"
               :style="{fontSize: sizing.moreButton, height: sizing.moreButtonHeight, width: sizing.moreButtonWidth}">
            More Details
          </div>
        </div>
        <div class="description" :style="{fontSize: sizing.description}">{{item.description}}</div>
      </div>

    </div>
  </div>
</template>

<script>

import axios from "axios";
import config from "@/config/constants";

export default {
  name: "ServicesCard",
  props: ["item","expanded","tileHeight","stopExpand","increaseFactor","standardTileWidth"],
  data() {
    return {
      servicesData: [
        {
          item_slug: "rewards",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/homepage_hero_v2.png",
          subTitle: "start a course",
          label: "rewards.",
          external_link: null,
          background_vector: "/img/vectors/blue.png",
        },
        {
          item_slug: "wellbeingSeries",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/ds.webp",
          subTitle: "start a course",
          label: "wellbeing series.",
          external_link: null,
          background_vector: "/img/vectors/blue.png",
        },
        {
          item_slug: "eap",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/eap.webp",
          subTitle: "call us for",
          label: "24/7 support.",
          external_link: null,
          background_vector: "/img/vectors/blurple.png",
        },
        {
          item_slug: "digitalGym",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/dg.webp",
          subTitle: "work out in the",
          label: "digital gym.",
          external_link: null,
          background_vector: "/img/vectors/dark.png",
        },
        {
          item_slug: "digitalClinicsGeneric",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/dc.webp",
          subTitle: "book into the",
          label: "digital clinics.",
          external_link: null,
          background_vector: "/img/vectors/dark-blurple.png",
        },
        {
          item_slug: "totalMentalHealth",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/tmh.webp",
          subTitle: "start your mental health",
          label: "coaching journey.",
          external_link: null,
          background_vector: "/img/vectors/green.png",
        },
        {
          item_slug: "cahms",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/cahms.webp",
          subTitle: "find out more about",
          label: "digital therapy portal.",
          external_link: null,
          background_vector: "/img/vectors/grey.png",
        },
        {
          item_slug: "cmh",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/cahms.webp",
          subTitle: "find out more about the",
          label: "digital therapy portal.",
          external_link: null,
          background_vector: "/img/vectors/lilac.png",
        },
        {
          item_slug: "bookAService",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/dc.webp",
          subTitle: "find out more about",
          label: "book a Service (GP) etc.",
          external_link: 'www.layahealthcare.ie/memberarea/#/services',
          background_vector: "/img/vectors/orange.png",
        },
        {
          item_slug: "fitness",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/fitness.webp",
          subTitle: "find out more about",
          label: "fitness.",
          external_link: null,
          background_vector: "/img/vectors/pink.png",
        },
        {
          item_slug: "beCalm",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/be-calm.webp",
          subTitle: "find out more about",
          label: "be calm.",
          external_link: null,
          background_vector: "/img/vectors/purple.png",
        },
        {
          item_slug: "soundSpace",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/soundspace.webp",
          subTitle: "find out more about",
          label: "sound space.",
          external_link: null,
          background_vector: "/img/vectors/light-green.png",
        },
        {
          item_slug: "nutrition",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/nutrition.webp",
          subTitle: "find out more about",
          label: "nutrition.",
          external_link: null,
          background_vector: "/img/vectors/rose.png",
        },
        {
          item_slug: "wellbeing",
          img: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/wellbeing.webp",
          subTitle: "find out more about",
          label: "wellbeing.",
          external_link: null,
          background_vector: "/img/vectors/yellow.png",
        },
      ]
    }
  },
  methods: {
    submitSamlResponse(samlResponse, acsUrl) {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = acsUrl;

      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'SAMLResponse';
      input.value = samlResponse;

      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
    },
    async getBenefitsUrl() {
      if(this.$store.getters.skin === 25 && this.$store.getters.user.access_code_user){
        window.location.href = 'https://spectrumbenefits.benefithub.com/';
      } else {
        const slPayload = {
          user_id: this.$store.getters.user_id,
        };
        let slRes = await axios.post(
            config.api_env + "/application/api/p/saml/sso",
            slPayload,
            config.options
        );
        this.submitSamlResponse(slRes.data.samlResponse, slRes.data.acsUrl);
      }
    },
    goToContent() {
      if(this.serviceData.external_link) {
        window.open("https://" + this.serviceData.external_link, "_blank");
      } else if(this.$store.getters.skin === 30 && this.serviceData.item_slug === 'rewards') {
        this.getBenefitsUrl();
      } else {
        this.$router.push({name: this.serviceData.item_slug})
      }
    },
    handleOverlayClick() {
      if(!this.stopExpand) return;
      this.$router.push({name: this.item.item_slug})
    }
  },
  computed: {
    cardCoverColour() {
      const vectorsWithLightText = ['blurple','dark','dark-blurple','orange','purple'];
      const parts = this.serviceData.background_vector.split('/');
      const fileName = parts[parts.length - 1];
      const colourName = fileName.split('.')[0];
      return vectorsWithLightText.includes(colourName) ? 'lightCardText' : 'darkCardText';
    },
    sizing() {
        return {
          subtitle: Math.floor(this.tileHeight * 0.03) + 'px',
          tileLabel:  Math.floor(this.tileHeight * 0.0525) + 'px',
          moreButton: Math.floor(this.tileHeight * 0.0405) + 'px',
          moreButtonHeight: Math.floor(this.tileHeight * 0.085) + 'px',
          moreButtonWidth: Math.floor(this.tileHeight * 0.35) + 'px',
          expandedTitle: Math.floor(this.tileHeight * 0.075) + 'px',
          plusIcon: Math.floor(this.tileHeight * 0.075) + 'px',
          description: Math.floor(this.tileHeight * 0.045) + 'px',
          overlayTop: Math.floor(this.tileHeight * 0.3) + 'px'
        }
    },
    getTileClass() {
      let classStr = this.item.item_slug;
      if(this.expanded && !this.stopExpand) classStr += " expanded";
      if(this.stopExpand) classStr += " stopExpand"
      return classStr;
    },
    serviceData() {
      const service = this.servicesData.filter(obj => obj.item_slug === this.item.item_slug);
      return service[0];
    }
  }
}

</script>
<style lang="less">
#ServicesCard {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  .lightCardText {
    color: white;
  }
  .expandedBackground {
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //border-radius: 15px;
    z-index: 0;
  }
  .backgroundContainer {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    //transition: opacity 0.25s ease-in;
    //transition-delay: 0.5s;
  }
  .unexpandedOverlay {
    opacity: 1;
    height: 80%;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 3;
    //transition-delay: 0.85s;
    transition: opacity 1s ease-in-out 1s;
    //border-radius: 15px;
    .iconHolder {
      width: 30%;
      position: absolute;
      bottom: 0;
      right: 0;
      img {
        width: 45%;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .textContainer {
      width: 70%;
      height: fit-content;
      opacity: 1;
      color: #122130;
      position: absolute;
      bottom: 0;
      left: 5%;
      z-index: 4;
      display: flex;
      flex-direction: column;
      .tileLabel {
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      }
    }
  }
  .expandedOverlay {
    opacity: 0;
    //transition: none;
    //transition-delay: 0.25s;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    //border-radius: 15px;
    position: relative;
    .expandedOverlayInner {
      position: absolute;
      padding-left: 10%;
      background: linear-gradient(83.35deg, #000000 16.67%, rgba(0, 0, 0, 0) 98.17%);
      width: 100%;
      height: 100%;
    }
    //
    .expandedTitle {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      width: 75%;
    }
    .btnRow {
      align-items: center;
      display: flex;
      .moreButton {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        border-radius: 30px;
        //background: linear-gradient(90deg, #112231 0%, #937FF8 100%);
        //background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);

      }
      i {
        margin-left: 20px;
        color: #FFFFFFAB;
      }
    }
    .description {
      width: 60%;
    }
  }
  //stopExpand
  &.stopExpand {
    .unexpandedOverlay {
      cursor: pointer;
    }
  }
  //expanded
  &.expanded {
    .backgroundContainer {
      opacity: 0;
      //transition: none;
      //transition-delay: 0s;
    }
    .expandedOverlay {
      opacity: 1;
      //transition: opacity 0.5s ease-in;
      //transition-delay: 0.75s;
    }
    .unexpandedOverlay {
      opacity: 0;
      z-index: -1;
      transition-delay: 0s !important;
    }
  }
  //  service specific classes
  &.cahms, &.cmh, &.beCalm, &.fitness, &.soundSpace,&.nutrition, &.wellbeing, &.rewards {
    .unexpandedOverlay {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    }
  }
  &.eap {
    .unexpandedOverlay {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    }
  }
  &.digitalClinicsGeneric {
    .unexpandedOverlay {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    }
  }
  &.wellbeingSeries {
    .unexpandedOverlay {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    }
  }
  &.totalMentalHealth, &.bookAService {
    .unexpandedOverlay {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    }
  }
  &.digitalGym {
    .unexpandedOverlay {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    }
    .backgroundContainer {
      background-position: 92%;
    }
  }
}
</style>