<template>
    <div class="bg-white" id="DigitalFrontDoor" ref="top">
        <FeaturesCarousel title="Featured" type="featured-event" v-if="products.wellbeingSeries"/>
        <CustomHomeSlider :items="services" :tile-type="'services'" v-if="services.length"/>
        <div v-if="messageToDisplay">
          <div class="container">
            <div v-html="messageToDisplay" class="homepageMessage"></div>
          </div>
        </div>
        <div class="pt-5 carousel-container">
            <HomeFilters />
            <HomeCardCarousel
                v-if="products.wellbeingSeries" :title="componentText.topEvents" type="studio" class="car" perma-tag="Top Events This Month" :mute="true"></HomeCardCarousel>
            <HomeCardCarousel
                v-if="products.wellbeingSeries" :title="'Upcoming Events'" type="studio" class="car" :upcoming="true"></HomeCardCarousel>
            <HomeCardCarousel
                v-if="showDcs" :title="'Book a Digital Clinic Service'" type="clinic" class="car"></HomeCardCarousel>
            <HomeCardCarousel
                promo-text="Watch expert speakers in a series of events and seminars"
                v-if="products.wellbeingSeries" title="Wellbeing Studio" class="car" type="studio" :mute="true"></HomeCardCarousel>
            <HomeCardCarousel
                promo-text="Health and wellbeing video courses"
                v-if="products.wellbeing" title="Courses" type="courses" class="car"></HomeCardCarousel>
            <HomeCardCarousel
                promo-text="Live and on-demand classes like yoga, Pilates and HIIT"
                v-if="products.digitalGym" title="Classes" type="gym" class="car"></HomeCardCarousel>
            <HomeCardCarousel
                promo-text="On-demand training plans to suit a range of fitness levels"
                v-if="products.digitalGym" title="Training Plans" type="exercise" class="car"></HomeCardCarousel>
            <HomeCardCarousel
                promo-text="Audio courses to help with sleep, anxiety, stress and more"
                v-if="products.beCalm" title="Be Calm" type="be_calm" class="car"></HomeCardCarousel>
            <HomeCardCarousel
                promo-text="Access new recipes and nutritional guides"
                v-if="products.nutrition" title="Nutrition" type="recipes" class="car"></HomeCardCarousel>
            <HomeCardCarousel
                promo-text="Listen to the latest podcasts in the world of wellbeing"
                v-if="products.soundSpace" title="Sound Space" type="Podcasts" class="car"></HomeCardCarousel>
        </div>
    </div>
</template>
<script>
import EapBanner from "@/components/Home/New/EapBanner.vue";
import HomeReminders from "@/components/Home/New/HomeReminders.vue"
import HomeCardCarousel from "@/components/Home/New/HomeCardCarousel.vue"
import HomeDigitalClinicsBanner from "@/components/Home/New/HomeDigitalClinicsBanner.vue"
import HomeFrontDoor from "@/components/Home/New/HomeFrontDoor.vue"
import HomeFilters from "@/components/Home/New/HomeFilters.vue"
import HomeEvents from "@/components/Home/New/HomeEvents.vue"
import { mapGetters } from "vuex";
import EventBus from "@/helpers/eventbus";
import ServicesDisplay from "@/components/Home/New/ServicesDisplay.vue";
import FeaturesCarousel from "@/components/Home/New/FeaturesCarousel.vue";
import CustomHomeSlider from "@/components/Home/New/CustomHomeSlider.vue";

export default {
    components: {
        EapBanner,
        HomeCardCarousel,
        HomeDigitalClinicsBanner,
        HomeFrontDoor,
        HomeFilters,
        HomeEvents,
        HomeReminders,
        ServicesDisplay,
        FeaturesCarousel,
        CustomHomeSlider
    },
    name: "DigitalFrontDoor",
    data() {
        return {
          bookings: [],
          services: [],
          bookingsLoading: true,
          displayWallet: false,
          optionalTag: null,
          company_homepage_message: null,
          products: {
            wellbeingSeries: false,
            wellbeing: false,
            totalMentalHealth: false,
            soundSpace: false,
            nutrition: false,
            events: false,
            eap: false,
            digitalGym: false,
            digitalClinicsGeneric: false,
            beCalm: false,
          }

        };
    },
    mounted() {
      const _t = this;
      EventBus.$on('updateDFDReminders',function() {
        _t.getBookings();
      });
      this.extractProductsFromNav(this.nav);
      this.extractProductsFromNav(this.sideNav);
      this.getServicesData();
      this.getBookings();
      this.getCompanyHomepageMessage();
      let self = this;
      EventBus.$on("setTag", function (tag) {
          if (tag) {
              self.optionalTag = [tag.tag];
          } else {
              self.optionalTag = [];
          }
      });
    },
    beforeDestroy() {

    },
    methods: {
      async getCompanyHomepageMessage() {
        const res = await this.api({
          path: 'api/u/customisations/get-company-homepage-message'
        })
        if(res.success) {
          this.company_homepage_message = res.company_homepage_message;
        }
      },
      async getServicesData() {
        let res = await this.api({
          path: "api/u/home/get-front-door-banner",
        });
        if (res.success) {
          const services = [];
          res.banner.forEach(card => {
            let include = true;
            services.forEach(svc => {
              if(svc.item_slug === card.item_slug) include = false;
            })
            if(include) services.push(card);
          })
          this.services = services;
          this.loading = false;
        }
      },
      extractProductsFromNav(nav) {
        nav.forEach(navItem => {
          if(this.products.hasOwnProperty(navItem.item_slug)) this.products[navItem.item_slug] = true;
          if(navItem.hasOwnProperty('sub_items')) {
            navItem.sub_items.forEach(subItem => {
              if(this.products.hasOwnProperty(subItem.item_slug)) this.products[subItem.item_slug] = true;
            })
          }
        })
      },
      async getBookings(){
        try {
          let res = await this.api({
            path: "api/u/home-page/get-booking-widget",
          });
          if (res.success) {
            this.bookings = res.bookings;
            EventBus.$emit("setBookingReminders", this.bookings);

          }
          this.bookingsLoading = false;
        } catch (e) {
          this.bookingsLoading = false;
        }

      }
    },
    computed: {
      ...mapGetters(["nav","sideNav","skin"]),
      messageToDisplay() {
        return this.company_homepage_message ? atob(this.company_homepage_message) : null;
      },
      showDcs() {
        if(!this.products.digitalClinicsGeneric) return false;
        if(!this.optionalTag) return true;
        return this.optionalTag.length === 0;

      },
      componentText() {
        return this.getText.dfd;
      }
    }
};
</script>
<style lang="less">
#DigitalFrontDoor {
    transition: background-color 1s ease;

    #HomeDigitalClinicsBanner {
        margin-top: 5px !important;
        margin-bottom: 10px;
    }
    .home-top-container {
      max-width: 1300px;
      margin: auto;
    }

    .container {
      width: 98% !important;
      max-width: 2000px !important;
    }
    .banner-card-container {
      width: 90% !important;
    }
    .home-title {
        font-size: 2.4rem;
        font-family: "FilsonProBold", sans-serif;
        text-align: left;
        margin-bottom: 10px;
    }

    .home-title-underline {
        margin-left: 5px;
        height: 3px;
        width: 50px;
    }
   #HomeDigitalClinicsBanner {
     margin-top: 30px;
   }
}
@media only screen and (max-width: 767px) {
  #DigitalFrontDoor {
    .container {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .banner-holder {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}
</style>